import React, {FunctionComponent, useEffect, useState} from 'react';

import {IBotAction, IBotAnswer} from 'utils/hooks/use-chat-bot/interfaces';
import {IUseDictionary, Overlay, BotActionType} from '@wholesalechange/chatcomponent';
import {useLoadFile} from '../../utils/hooks/useLoadFile';

interface IXpeditionOverlay {
  botAnswers: IBotAnswer[]
  onChange: (message: IBotAction | null) => void
  onFinish: () => void
  getArticle: (code: string) => void
  onPressEnter: () => void
  dictionary: IUseDictionary
}

export const InterviewOverlay: FunctionComponent<IXpeditionOverlay> = ({
  botAnswers,
  onChange,
  onFinish,
  getArticle,
  onPressEnter,
  dictionary
}: IXpeditionOverlay) => {
  const [overlays, setOverlays] = useState<IBotAction[]>([]);
  const {getFileUrlAsync, removeFileAsync} = useLoadFile();

  useEffect(() => {
    const overlayList = botAnswers.reduce<IBotAction[]>((acc, answer) => {
      if (answer.keyboard?.buttons) {
        answer.keyboard.buttons.forEach((item) => {
          if (item.actionType === BotActionType.Overlay) {
            return acc.push(item);
          }

          return acc;
        });
      }

      return acc;
    }, []);

    setOverlays(overlayList);
  }, [botAnswers]);

  function handleChangeOverlay(overlayIdx: number) {
    return (actionBody: string) => {
      if (actionBody.length === 0) {
        onChange(overlays[overlayIdx]);

        return;
      }
      onChange({
        ...overlays[overlayIdx],
        actionBody
      });
    };
  }

  function handleFinishInterview() {
    onFinish();
  }

  function handleGetArticle(code: string) {
    getArticle(code);
  }

  function handlePressEnter() {
    onPressEnter();
  }

  return <>
    {
      overlays.map((overlay, overlayIdx) => {
        return <Overlay
          messageId={0}
          key={overlayIdx}
          overlay={overlay}
          onChange={handleChangeOverlay(overlayIdx)}
          onFinish={handleFinishInterview}
          getArticle={handleGetArticle}
          onPressEnter={handlePressEnter}
          dictionary={dictionary}
          getFileUrl={getFileUrlAsync}
          removeFile={removeFileAsync}
        />;
      })
    }
  </>;
};
