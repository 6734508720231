import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAmiz7gfz3_aDAUR33hswM4YloCoV99sJ4',
  authDomain: 'gritxapp.firebaseapp.com',
  databaseURL: 'https://gritxapp.firebaseio.com',
  projectId: 'gritxapp',
  storageBucket: 'gritxapp.appspot.com',
  messagingSenderId: '981019104245',
  appId: '1:981019104245:web:1e150bdf89d7efd62cc6bc',
  measurementId: 'G-6S4QNFC3NN',
  vapidKey: 'BPzePXxtZmG-FmyunR0shiuIr2FL97EapQj8YqTtXYp4jkqcXbJMGrAYSlPxG43nCigD_k7OO2NeabvwQQtTTEA'
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = (): Promise<string | null> => {
  return getToken(messaging, {vapidKey: firebaseConfig.vapidKey})
    .then((currentToken) => {
      console.log(`token: ${currentToken}`);
      if (currentToken) {
        return currentToken;
      }
      console.log('No registration token available. Request permission to generate one.');

      return null;
    })
    .catch((err) => {
      console.log(`An error occurred while retrieving token - ${err}`);

      return null;
    });
};

onMessage(messaging, (message) => {
  console.log(`onMessage call, payload = ${message}`);

  return new Notification(<string>message.notification?.title, {
    body: message.notification?.body,
    icon: message.notification?.icon
  });
});
