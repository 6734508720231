import {uploadMediaUrlApi} from '../../redux/api/media';
import {toBase64} from '../helpers/base64convert';
import {IImage} from '@wholesalechange/chatcomponent';

interface IUseUploadImage {
  uploadImageUrlAsync: (file: File) => Promise<IImage>
}

export function useUploadImage(): IUseUploadImage {
  async function uploadImageUrlAsync(file: File): Promise<IImage> {
    const contentData = await toBase64(file);
    const {data: image} = await uploadMediaUrlApi(contentData, file.name);

    return image;
  }

  return {
    uploadImageUrlAsync
  };
}
